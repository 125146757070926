import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from './Button';
import Input from './Input';
import Otpinput from './Otpinput';
import useAxios from '../hooks/useAxios';
import { replace, useFormik } from 'formik';
import * as yup from 'yup';
import { OTP_DIGITS } from '../constants/common';
import { usePersistState } from '../hooks/usePersistState';
import { PersistentStateEnum, TableStatusEnum } from '../constants/enum';
import { useAuthStore } from '../store/authStore';
import { Link, redirect, useNavigate } from '@tanstack/react-router';
import { ToastContainer } from 'react-toastify';
import { HotToast } from './HotToast';
import { AxiosError } from 'axios';
import { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord, parseAxiosError } from '../utils/common';
import clsx from 'clsx';

const CaptainRegister = ({
  tableId,
  restaurantName,
  tableName,
  restaurantId,
  userName,
  phoneNumber,
}: {
  tableId: string;
  restaurantName: string;
  tableName: string;
  restaurantId: string;
  userName: string;
  phoneNumber: string;
}) => {
  const {
    setAuthToken,
    setUserName,
    setTablePIN,
    setSessionID,
    setIsCaptain,
    setUserId,
    getRestaurantId,
    fingerPrint,
  } = useAuthStore((state) => ({
    setAuthToken: state.setAuthToken,
    setRefreshToken: state.setRefreshToken,
    setIdToken: state.setIdToken,
    setUserName: state.setUserName,
    setTablePIN: state.setTablePIN,
    setSessionID: state.setSessionID,
    setIsCaptain: state.setIsCaptain,
    setUserId: state.setUserId,
    getRestaurantId: state.getRestaurantId,
    fingerPrint: state.fingerPrint,
  }));
  // const [token, setToken] = usePersistState(PersistentStateEnum.AUTH_TOKEN, '');
  // const [refreshToken, setRefreshToken] = usePersistState(PersistentStateEnum.REFRESH_TOKEN, '');
  // const [idToken, setIdToken] = usePersistState(PersistentStateEnum.ID_TOKEN, '');
  const { postWithoutAuth } = useAxios();
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate({ from: '/register/$tableId' });
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(30); // Timer for 30 seconds

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (step === 1 && !resendEnabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval!);
            setResendEnabled(true);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [step, resendEnabled]);

  function setOtp(otp: Array<string>) {
    otpFormik.setFieldValue('otp', otp);
  }

  const otpValidationSchema = yup.object({
    otp: yup
      .array()
      .test('is-empty', `OTP must be ${OTP_DIGITS} digits`, (value) => {
        if (!value) return false;
        return value.every((v) => v !== '' && v !== undefined);
      })
      .test('is-number', 'Must be only digits', (value) => {
        if (!value) return false;
        return value.every((v) => /^[0-9]+$/.test(v));
      }),
  });

  const otpFormik = useFormik({
    initialValues: {
      otp: Array.from({ length: OTP_DIGITS }).map(() => ''), // Initialize with an empty string
    },
    validationSchema: otpValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await postWithoutAuth('/app/auth-customer/verify-phone', {
          otp: values.otp.join(''),
          userName: formik.values.userName,
          tableId: tableId,
          phoneNumber: formik.values.phoneNumber.toString(),
          deviceId: fingerPrint,
        });
        setAuthToken(response.token);
        setUserName(response.userName);
        setTablePIN(response.tablePin);
        setSessionID(response.sessionId);
        setIsCaptain(true);
        setUserId(response.userId);
        navigate({
          to: '/order',
          replace: true,
        });
      } catch (e) {
        let message;
        if (e instanceof AxiosError) {
          message = parseAxiosError(e);
          message = message.errorMessage;
        } else if (e instanceof Error) {
          message = e.message;
        } else {
          message = 'Unknown error occurred, please try again later';
        }
        HotToast({ message: message, type: 'error' });
      }
      setLoading(false);
    },
  });

  const validationSchema = yup.object({
    userName: yup
      .string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .matches(/^[a-zA-Z0-9.]+$/, 'Must not contain special characters or spaces'),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits')
      .required('Mobile Number is required'),
  });

  const formik = useFormik({
    initialValues: {
      userName: capitalizeFirstLetter(userName),
      phoneNumber: phoneNumber,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await postWithoutAuth('/app/auth-customer/login-phone', {
          userName: values.userName,
          phoneNumber: values.phoneNumber.toString(),
          tableId,
        });
        HotToast({ message: 'An OTP was sent to your phone number.', type: 'success' });
        setStep(1);
      } catch (e) {
        let message;
        if (e instanceof AxiosError) {
          message = parseAxiosError(e);
          message = message.errorMessage;
        } else if (e instanceof Error) {
          message = e.message;
        } else {
          message = 'Unknown error occurred, please try again later';
        }
        HotToast({ message: message, type: 'error' });
      }
      setLoading(false);
    },
  });

  const userFormik = useFormik({
    initialValues: {
      userName: capitalizeFirstLetter(userName),
      phoneNumber: phoneNumber,
      otp: '',
    },
    onSubmit: async (values) => {},
  });

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const response = await postWithoutAuth('/app/auth-customer/login-phone', {
        userName: formik.values.userName,
        phoneNumber: formik.values.phoneNumber.toString(),
        tableId,
      });
      HotToast({ message: 'OTP resent successfully!', type: 'success' });
      setResendEnabled(false);
      setTimer((prevTimer) => prevTimer + 30); // Reset the timer
    } catch (e) {
      let message;
      if (e instanceof AxiosError) {
        message = parseAxiosError(e);
        message = message.errorMessage;
      } else if (e instanceof Error) {
        message = e.message;
      } else {
        message = 'Unknown error occurred, please try again later';
      }
      HotToast({ message: message, type: 'error' });
    }
    setLoading(false);
  };

  return (
    <div className="w-full h-full rounded-2xl flex flex-col justify-center items-center">
      <ToastContainer />
      {step === 0 && (
        <div className="w-full flex flex-col justify-around items-center gap-2 h-full px-4 my-10">
          <div className="flex flex-col justify-between items-center gap-0 w-full">
            <p className="text-2xl font-medium mb-1 text-center">{restaurantName}</p>
            <p className="text-base font-normal mb-4">Table: {tableName}</p>
          </div>
          <div className="flex flex-col justify-between items-center gap-4 w-full">
            <div className="w-full">
              <Input
                placeholder="Name"
                type="text"
                name="userName"
                value={formik.values.userName}
                onChange={formik.handleChange}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className="mt-1 px-2 text-red-500 text-sm w-full text-left">{formik.errors.userName}</div>
              ) : null}
            </div>
            <div className="w-full mb-2">
              <Input
                placeholder="Mobile Number"
                type="number"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="mt-1 px-2 text-red-500 text-sm w-full text-left">{formik.errors.phoneNumber}</div>
              ) : null}
            </div>
          </div>

          <Button title="Generate OTP" type="primary" isLoading={loading} onClick={formik.handleSubmit} />
          <Link to={`/restaurants/${getRestaurantId()}`} className="text-primary underline font-medium">
            View menu
          </Link>
        </div>
      )}
      {step === 1 && (
        <div className="w-full flex flex-col justify-around items-center gap-2 h-full px-4 my-10">
          <p className="text-2xl font-medium mb-4">{capitalizeFirstLetterOfEachWord(restaurantName)}</p>
          <Otpinput digits={OTP_DIGITS} otp={otpFormik.values.otp} setOtp={setOtp} />
          {otpFormik.touched.otp && otpFormik.errors.otp ? (
            <div className="mt-1 px-2 text-red-500 text-sm w-full text-left">{otpFormik.errors.otp}</div>
          ) : null}
          <div className="w-[80%]">
            <Button title="Verify" type="primary" isLoading={loading} onClick={otpFormik.handleSubmit} />
          </div>
          <div className="flex flex-col items-center">
            {!resendEnabled && <p className="text-gray-600">Resend OTP in {timer}s</p>}
            {resendEnabled && (
              <span
                className={clsx('underline', {
                  'text-primary cursor-pointer': resendEnabled,
                  'text-gray-400 cursor-not-allowed': !resendEnabled,
                })}
                onClick={resendEnabled ? handleResendOtp : undefined}
              >
                Resend OTP
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CaptainRegister;

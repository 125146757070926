import React, { Dispatch, SetStateAction } from 'react';

const RadioButton = ({
  label,
  name,
  value,
  checked,
  onChange,
}: {
  label?: string;
  name: string;
  value: string | number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="appearance-auto h-4 w-4 accent-primary transition duration-150 ease-in-out border rounded-full"
      />
      {label && <label className="text-gray-900">{label}</label>}
    </div>
  );
};

export default RadioButton;

import React from 'react';

const SkeletonCategory = () => {
  return (
    <div className="border px-4 py-1 rounded-lg animate-pulse">
      <div className="bg-gray-200 h-6 w-24 rounded"></div>
    </div>
  );
};

export default SkeletonCategory;

import { AiFillHome } from 'react-icons/ai';
import { MdQrCodeScanner } from 'react-icons/md';
import { motion, useScroll, useMotionValue, useMotionValueEvent } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link } from '@tanstack/react-router';

const Navbar = () => {
  const [hidden, setHidden] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const previous = scrollY.getPrevious();
    if (previous && latest > previous) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });
  const buttonVariants = {
    initial: {
      scale: 1,
      backgroundColor: '#4CAF50' /* Green */,
    },
    pressed: {
      scale: 0.9,
      backgroundColor: '#388E3C' /* Darker green */,
    },
  };

  return (
    <div className="w-full flex justify-center items-center">
      <motion.div
        initial={{ y: 0 }}
        animate={{ y: hidden ? '200%' : 0 }}
        className="bg-white shadow-navbar w-[80%] fixed bottom-4  flex justify-evenly items-center rounded-full navbar-transition"
      >
        <div className="p-2 bg-slate-100 rounded-full">
          <Link to="/" className="text-app_grey [&.active]:text-primary">
            <motion.span whileTap={{ scale: 0.9 }}>
              <AiFillHome className="text-2xl" />
            </motion.span>
          </Link>
        </div>
        <img src="/images/cibo_logo_primary.svg" alt="" className="relative bottom-6 h-[50px]" />
        <div className="p-2 bg-slate-100 rounded-full">
          <Link to="/scan" className="text-app_grey [&.active]:text-primary">
            <MdQrCodeScanner className="text-2xl " />
          </Link>
        </div>
      </motion.div>
    </div>
  );
};
export default Navbar;

import Lottie from 'lottie-react';
import React from 'react';
import mainLoading from '../assets/animations/main_loading.json';
import error from '../assets/animations/error.json';
import { Link } from '@tanstack/react-router';
// import spoonLoading from '../assets/animations/spoon_loading.json';
const CustomError = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 w-full h-screen">
      <Lottie className="w-auto h-[20vh]" animationData={error} loop={false} />
      {children}
    </div>
  );
};

export default CustomError;

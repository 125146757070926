import Lottie from 'lottie-react';
import React from 'react';
import mainLoading from '../assets/animations/main_loading.json';
// import spoonLoading from '../assets/animations/spoon_loading.json';
const Loading = ({className = "" } : {className? : string}) => {
  return (
    <div className={`flex flex-col justify-center items-center gap-2 w-full h-screen ${className}`}>
      <Lottie className="w-auto h-[30vh]" animationData={mainLoading} loop={true} />
    </div>
  );
};

export default Loading;

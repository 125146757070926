import clsx from 'clsx';
import { CartInterface } from '../Interface';
import { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord, getCustomisation } from '../utils/common';
import { ItemCounter } from './Dish';
import { useCartStore } from '../store/cartStore';
import { HotToast } from './HotToast';

const OrderReviewDish = ({
  item,
  index,
  cartLength,
  userName,
}: {
  item: CartInterface;
  index: number;
  cartLength: number;
  userName: string;
}) => {
  const { updateCart } = useCartStore();

  const addToCartHandler = () => {
    if (item.userName !== userName) {
      HotToast({ message: "You can't add other's dishes", type: 'info' });
      return;
    }
    updateCart([{ uuid: item.uuid, quantity: 1 }]);
  };

  const removeFromCartHandler = () => {
    if (item.quantity > 0) {
      updateCart([{ uuid: item.uuid, quantity: -1 }]);
    }
  };

  return (
    <div
      key={index}
      className={clsx('grid grid-cols-[3fr_1.5fr_1fr] gap-2 pb-3 mr-2', {
        'border-b mb-3': index !== cartLength - 1,
      })}
    >
      <div className="flex flex-col justify-center items-start">
        <p className="text-xs text-gold_start">{capitalizeFirstLetter(item.userName)}</p>
        <p className="font-medium">{capitalizeFirstLetterOfEachWord(item.dishName)}</p>
        {item.customisations && <p className="text-xs text-grey_text">{getCustomisation(item.customisations)}</p>}
      </div>
      <div className="flex justify-center items-center">
        <ItemCounter
          removeItem={removeFromCartHandler}
          count={item.quantity}
          addItem={addToCartHandler}
          addDisabled={item.userName !== userName}
        />
      </div>
      <div className="flex justify-center items-center">&#8377;{item.quantity * item.price}</div>
    </div>
  );
};

export default OrderReviewDish;

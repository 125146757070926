// Modal.tsx
import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import { IoClose } from 'react-icons/io5';
import { motion } from 'framer-motion';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  showClose?: boolean;
}

const CustomModal: React.FC<Props> = ({ isOpen, onClose, children, showClose = true }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={showClose}
      className="fixed w-[85%] h-fit top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg shadow-lg z-50 focus:outline-none"
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-50 focus:outline-none"
    >
      <div className="relative">
        {showClose && (
          <div className="w-full flex justify-end items-center">
            <motion.button
              className="focus:outline-none"
              style={{ border: 'none', background: 'transparent' }}
              whileTap={{ scale: 0.97 }}
              onClick={onClose}
            >
              <motion.div transition={{ duration: 0.2 }}>
                <IoClose style={{ outline: 'none' }} />
              </motion.div>
            </motion.button>
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;

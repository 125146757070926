import React from 'react';
import { FoodType } from '../Interface';
import clsx from 'clsx';
function FoodTypeIndicator({ type}: { type: FoodType; }) {
  return (
    <div
      className={clsx('flex items-center justify-center border-1 rounded-[3px] h-4 w-4 border-[1px]', {

        'border-primary': type === FoodType.NON_VEG,
        'border-veg_green': type === FoodType.VEG,
      })}
    >
      <div
        className={clsx('rounded-full h-2 w-2', {
          'bg-primary': type === FoodType.NON_VEG,
          'bg-veg_green': type === FoodType.VEG,
        })}
      ></div>
    </div>
  );
}

export default FoodTypeIndicator;

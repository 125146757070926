// import { motion } from 'framer-motion';
// import React, { useState } from 'react';
// import { FaChevronDown } from 'react-icons/fa6';

// function CollapsibleCard({ children }: { children: React.ReactNode }) {
//   const [isOpen, setIsOpen] = useState<boolean>(true);
//   const childrenArray = React.Children.toArray(children);
//   const firstChild = childrenArray[0];
//   const remainingChildren = childrenArray.slice(1);
//   return (
//     <motion.div className="rounded-xl border bg-card text-card-foreground shadow w-full h-fit">
//       <motion.div
//         onClick={() => {
//           setIsOpen((prev) => !prev);
//         }}
//         className="space-y-1.5 px-6 py-2 flex justify-center items-start"
//       >
//         {firstChild}
//         <FaChevronDown />
//       </motion.div>
//       {isOpen && <hr />}
//       {isOpen && <motion.div className="flex items-center px-6 pt-3">{remainingChildren}</motion.div>}
//     </motion.div>
//   );
// }

// export default CollapsibleCard;

import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';

function CollapsibleCard({ children, className = '' }: { children: React.ReactNode; className: string }) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const childrenArray = React.Children.toArray(children);
  const firstChild = childrenArray[0];
  const remainingChildren = childrenArray.slice(1);

  return (
    <motion.div className={clsx(className, ' rounded-xl shadow-orderHistory bg-white')}>
      <motion.div
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        className="px-6 py-2 flex justify-center items-center cursor-pointer"
        initial={false}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        {firstChild}
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className="ml-auto text-grey_text "
        >
          <FaChevronDown />
        </motion.div>
      </motion.div>
      <AnimatePresence>
        {/* {isOpen && <hr />} */}
        {/* {isOpen && (
          <motion.div
            key="divider"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="overflow-hidden"
          >
            <hr />
          </motion.div>
        )} */}
        {isOpen && (
          <motion.div
            key="content"
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="px-6 pt-3 overflow-hidden"
          >
            {remainingChildren}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default CollapsibleCard;

import React from 'react';
import { motion } from 'framer-motion';

interface SwitchProps {
  isOn: boolean;
  onClick: () => void;
}

function Switch({ isOn, ...props }: SwitchProps) {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <motion.div transition={{ duration: 0.3 }} className="switch" data-ison={isOn} {...props}>
      <motion.div className="handle" layout transition={spring} />
    </motion.div>
  );
}

export default Switch;

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LocalStorageEnum } from '../constants/enum';

type AuthStoreType = {
  refreshToken: string | null;
  authToken: string | null;
  idToken: string | null;
  tablePIN: string | null;
  sessionID: string | null;
  tableId: string | null;
  restaurantId: string | null;
  userName: string | null;
  isCaptain: boolean;
  checkout: boolean;
  fingerPrint: string | null;
  userId: string | null;

  setAuthToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  setIdToken: (token: string) => void;
  setTablePIN: (tablePIN: string) => void;
  setSessionID: (sessionID: string) => void;
  setTableID: (tableID: string) => void;
  setRestaurantID: (restaurantId: string) => void;
  setUserName: (userName: string) => void;
  setCheckout: (checkout: boolean) => void;
  setIsCaptain: (isCaptain: boolean) => void;
  setFingerPrint: (fingerPrint: string) => void;
  setUserId: (userId: string) => void;
  clearTokens: () => void;

  isAuthenticated: () => boolean;
  getAuthToken: () => string | null;
  getRefreshToken: () => string | null;
  getIdToken: () => string | null;
  getTablePIN: () => string | null;
  getSessionID: () => string | null;
  getTableID: () => string | null;
  getRestaurantId: () => string | null;
  getFingerPrint: () => string | null;
};

export const useAuthStore = create<AuthStoreType>()(
  persist(
    (set, get) => ({
      refreshToken: null,
      authToken: null,
      idToken: null,
      tablePIN: null,
      sessionID: null,
      tableId: null,
      restaurantId: null,
      userName: null,
      isCaptain: false,
      checkout: false,
      fingerPrint: null,
      userId: null,

      setAuthToken: (token: string) => set({ authToken: token }),
      setRefreshToken: (token: string) => set({ refreshToken: token }),
      setIdToken: (token: string) => set({ idToken: token }),
      setSessionID: (sessionID: string) => set({ sessionID: sessionID }),
      setTableID: (tableID: string) => set({ tableId: tableID }),
      setTablePIN: (tablePIN: string) => set({ tablePIN: tablePIN }),
      setRestaurantID: (restaurantId: string) => set({ restaurantId: restaurantId }),
      setUserName: (userName: string) => set({ userName: userName }),
      setIsCaptain: (isCaptain: boolean) => set({ isCaptain: isCaptain }),
      setFingerPrint: (fingerPrint: string) => set({ fingerPrint: fingerPrint }),
      setUserId: (userId: string) => set({ userId: userId }),
      clearTokens: () =>
        set({
          refreshToken: null,
          authToken: null,
          idToken: null,
          tablePIN: null,
          sessionID: null,
          tableId: null,
          restaurantId: null,
          userName: null,
          isCaptain: false,
          checkout: false,
        }),
      setCheckout: (checkout: boolean) => set({ checkout: checkout }),
      isAuthenticated: () => !!get().authToken,

      getAuthToken: () => get().authToken,
      getRefreshToken: () => get().refreshToken,
      getIdToken: () => get().idToken,
      getTablePIN: () => get().tablePIN,
      getSessionID: () => get().sessionID,
      getTableID: () => get().tableId,
      getRestaurantId: () => get().restaurantId,
      getFingerPrint: () => get().fingerPrint,
    }),
    {
      name: LocalStorageEnum.AUTH_STORAGE,
    },
  ),
);

import clsx from 'clsx';
import { UserSplit } from '../Interface/Bill';
import { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord, getCustomisation } from '../utils/common';

export default function BillSplitCard({ userSplit, bottomBorder }: { userSplit: UserSplit; bottomBorder: boolean }) {
  return (
    <div
      className={clsx('flex flex-col py-2', {
        'border-b': bottomBorder,
      })}
    >
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold text-primary">{capitalizeFirstLetter(userSplit.userName)}</p>
        <p className="text-lg font-semibold text-primary text-right">&#8377;{userSplit.totalAmount}</p>
      </div>
      <div className="pt-1">
        {userSplit.dishes.map((dish, index) => (
          <div className="flex items-start" key={index}>
            <div className="flex flex-col justify-center items-start w-full overflow-hidden text-ellipsis basis-8/12">
              <p className="text-ellipsis overflow-hidden  ">{capitalizeFirstLetterOfEachWord(dish.name)}</p>
              {dish.customisations && <p className="text-xs text-grey_text">{getCustomisation(dish.customisations)}</p>}
            </div>
            <div className="flex justify-center items-center basis-2/12 font-medium">
              <p>x{dish.quantity}</p>
            </div>
            <div className="basis-2/12 text-right">
              <p>&#8377;{dish.totalPrice}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

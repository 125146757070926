import Lottie from 'lottie-react';
import React from 'react';
import mainLoading from '../assets/animations/main_loading.json';
import notFound from '../assets/animations/not_found.json';
import { Link } from '@tanstack/react-router';
// import spoonLoading from '../assets/animations/spoon_loading.json';
const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 w-full h-screen">
      <Lottie className="w-auto h-[50vh]" animationData={notFound} loop={true} />
      <Link to="/" className="text-primary underline font-medium">
        Go back to home
      </Link>
    </div>
  );
};

export default NotFound;

import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import useMeasure from 'react-use-measure';
import { useDragControls, useMotionValue, useAnimate, motion } from 'framer-motion';

const CustomDrawer = ({
  open,
  setOpen,
  children,
  drawerHeight = 75,
  onClose,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  drawerHeight?: number;
  onClose?: () => void;
}) => {
  const [scope, animate] = useAnimate();
  const [drawerRef, { height }] = useMeasure();

  const y = useMotionValue(0);
  const controls = useDragControls();

  const handleClose = async () => {
    if (onClose) {
      onClose();
    }
    animate(scope.current, {
      opacity: [1, 0],
    });

    const yStart = typeof y.get() === 'number' ? y.get() : 0;

    await animate('#drawer', {
      y: [yStart, height],
    });

    setOpen(false);
  };

  return (
    <>
      {open && (
        <motion.div
          ref={scope}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={handleClose}
          className="fixed inset-0 z-50 bg-neutral-700/60"
        >
          <motion.div
            id="drawer"
            ref={drawerRef}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: '100%' }}
            animate={{ y: '0%' }}
            transition={{
              ease: 'easeInOut',
            }}
            className="absolute bottom-0  w-full overflow-hidden rounded-t-3xl bg-dish_grey"
            style={{ y, height: `${drawerHeight}vh` }}
            drag="y"
            dragControls={controls}
            onDragEnd={() => {
              if (y.get() >= 100) {
                handleClose();
              }
            }}
            dragListener={false}
            dragConstraints={{
              top: 0,
              bottom: 0,
            }}
            dragElastic={{
              top: 0,
              bottom: 0.5,
            }}
          >
            <div className="absolute left-0 right-0 top-0 z-10 flex justify-center bg-dish_grey p-4">
              <button
                onPointerDown={(e) => {
                  controls.start(e);
                }}
                className="h-1 w-8 cursor-grab touch-none rounded-full bg-grey_text active:cursor-grabbing"
              ></button>
            </div>
            <div className="relative z-0 h-full overflow-y-scroll p-4 pt-12 no-scrollbar">{children}</div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default CustomDrawer;

import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { parseUrl } from '../utils/common';

const Scan = () => {
  const navigate = useNavigate({ from: '/scan' });
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const onScanSuccess = (result: QrScanner.ScanResult) => {
    const parsedURL = parseUrl(result.data);
    if (parsedURL) {
      const empressURL = parseUrl(window.location.href);
      if (parsedURL.host === empressURL?.host && parsedURL.route === 'register' && parsedURL.param) {
        navigate({
          to: '/register/$tableId',
          params: { tableId: parsedURL.param },
        });
      }
    }
  };

  const onScanFail = (err: string | Error) => {};

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: 'environment',
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  // ❌ If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (!qrOn)
      alert('Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.');
  }, [qrOn]);

  return (
    <div className="w-full md:w-[430px] h-[100vh] my-0 mx-auto relative">
      {/* QR */}
      <video className="h-full object-cover" ref={videoEl}></video>
      <div ref={qrBoxEl} className="!w-full !left-0">
        <img
          src={'images/qr_scan_overlay.svg'}
          alt="Qr Frame"
          width={256}
          height={256}
          className="absolute fill-none left-[50%] top-[50%] w-full transform -translate-x-1/2 -translate-y-1/2"
        />
      </div>
    </div>
  );
};
export const Route = createFileRoute('/scan')({
  component: Scan,
});

export default Scan;

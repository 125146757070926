import { IoOptionsOutline } from 'react-icons/io5';
import React, { useState } from 'react';
import { motion, Variants } from 'framer-motion';
import { MenuType, RestaurantCategoryInterface } from '../Interface';

function FoodOptions({ menu, onCategoryClick }: { menu: MenuType; onCategoryClick: (categoryId: string) => void }) {
  const [isOpen, setIsOpen] = useState(false);
  const itemVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };
  return (
    <motion.nav
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      className="relative flex flex-col justify-center items-end shadow-md rounded-full"
    >
      <motion.button
        className="bg-primary p-2.5 rounded-full shadow-md"
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <motion.div transition={{ duration: 0.2 }}>
          <IoOptionsOutline className="text-white text-xl" />
        </motion.div>
      </motion.button>
      <motion.ul
        className="absolute max-h-80 overflow-y-scroll no-scrollbar top-11 list-none m-0 p-2 w-36 flex flex-col gap-2 bg-primary"
        variants={{
          open: {
            clipPath: 'inset(0% 0% 0% 0% round 10px)',
            transition: {
              type: 'tween',
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05,
            },
          },
          closed: {
            clipPath: 'inset(10% 50% 90% 50% round 10px)',
            transition: {
              type: 'tween',
              bounce: 0,
              duration: 0.3,
            },
          },
        }}
        style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
      >
        {menu.map((item: RestaurantCategoryInterface, index) => (
          <motion.li
            key={index}
            className="text-white block list-none m-0 pt-2"
            variants={itemVariants}
            onClick={() => {
              setIsOpen(false);
              onCategoryClick(item.restaurantCategoryId);
            }}
          >
            <p className="mb-2 pl-2">{item.restaurantCategoryName}</p>
            <hr />
          </motion.li>
        ))}
      </motion.ul>
    </motion.nav>
  );
}

export default FoodOptions;

import React from 'react';
import { copyToClipboard } from '../utils/common';
import Drawer from '../components/Drawer';
import { MdContentCopy } from 'react-icons/md';
import {
  EmailShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
function Share({
  url,
  title,
  isOpen,
  setIsOpen,
}: {
  url: string;
  title: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Drawer drawerHeight={30} open={isOpen} setOpen={setIsOpen}>
      <div className="flex flex-col justify-center items-center gap-3 h-full">
        <div className="flex flex-col justify-center items-center text-sm">
          <a href={url} className="underline text-blue-600 break-all text-center text-xs">
            {url}
          </a>
          <div
            onClick={() => {
              copyToClipboard(url);
            }}
            className="flex gap-1 justify-center items-center bg-slate-200 px-2 py-0.5 rounded-lg mt-2"
          >
            <MdContentCopy />
            <p className="select-none">Copy</p>
          </div>
        </div>
        <hr className=" w-[75%] mx-auto" />
        <div className="flex gap-4 justify-center items-center">
          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          <TelegramShareButton url={url} title={title}>
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
          <FacebookShareButton url={url} title={title}>
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
        </div>
      </div>
    </Drawer>
  );
}

export default Share;

import React from 'react';

const SkeletonDish = () => {
  return (
    <div className="rounded-xl bg-dish_grey grid grid-cols-[2fr_4fr_2fr] p-3 gap-2 animate-pulse">
      <div className="bg-gray-200 rounded-xl w-full h-24"></div>
      <div className="overflow-hidden space-y-3">
        <div className="bg-gray-200 h-6 w-3/4 rounded"></div>
        <div className="bg-gray-200 h-4 w-1/2 rounded"></div>
        <div className="bg-gray-200 h-4 w-1/4 rounded"></div>
      </div>
      <div className="flex flex-col justify-start items-end gap-2">
        <div className="bg-gray-200 h-6 w-12 rounded"></div>
        <div className="bg-gray-200 h-6 w-16 rounded"></div>
        <div className="bg-gray-200 h-8 w-20 rounded"></div>
      </div>
    </div>
  );
};

export default SkeletonDish;

class SessionExpiredError extends Error {
  statusCode: number;

  constructor(message: string) {
    super(message);
    this.name = 'SessionExpiredError';
    this.statusCode = 401;
  }
}

export default SessionExpiredError;

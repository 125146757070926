import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LocalStorageEnum, socketCallEnum } from '../constants/enum';
import { CartInterface, DishInterface } from '../Interface';
import { capitalizeFirstLetter, compareSelectedFields, getAuthToken } from '../utils/common';
import { Socket, io } from 'socket.io-client';
import { HotToast } from '../components/HotToast';

export enum ActivityType {
  ADD_TO_CART = 'add-to-cart',
  REMOVE_FROM_CART = 'remove-from-cart',
  UPDATE_CART = 'update-cart',
  ORDER = 'order',
  USER_JOIN = 'user-join',
  USER_LEAVE = 'user-leave',
}
export enum OrderStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface RefreshCartResponse {
  status: 'success' | 'error';
  dishes?: Array<CartInterface>;
  message?: string;
}

type CartStoreType = {
  cart: Array<CartInterface>;
  socket: Socket | null;
  users: Array<{ name: string; socketId: string }>;
  cartLoading: boolean;
  shouldDisplayNavbar: boolean;
  isSocketConnected: boolean;

  initializeSocket: () => () => void;
  joinSession: (userName: string) => void;
  leaveSession: () => void;
  updateSession: (dish: CartInterface) => void;
  addToCart: (dish: CartInterface, loading?: boolean) => void;
  updateCart: (
    dishs: Array<{
      uuid: string;
      quantity: number;
    }>,
    loading?: boolean,
  ) => void;
  removeFromCart: (dishId: string) => void;
  clearCart: () => void;

  getCart: () => Array<CartInterface>;
  placeOrder: (cookingInstructions: string) => void;
  changeNavbarVisibility: (visibility: boolean) => void;
};

export const useCartStore = create<CartStoreType>()(
  persist(
    (set, get) => ({
      cart: [],
      socket: null,
      users: [],
      cartLoading: false,
      shouldDisplayNavbar: true,
      isSocketConnected: false,

      changeNavbarVisibility: (visibility: boolean) => {
        set({
          shouldDisplayNavbar: visibility,
        });
      },
      initializeSocket: () => {
        const authToken = getAuthToken();
        if (!authToken) {
          return () => 0;
        }
        const URL = import.meta.env.VITE_WSS_URL;
        const socket: Socket = io(URL, {
          transports: ['websocket', 'polling'],
          auth: { token: `Bearer ${authToken}` },
          reconnection: true, // Enable automatic reconnection
          reconnectionAttempts: 5, // Try to reconnect up to 5 times
          reconnectionDelay: 1000, // Initial delay between reconnection attempts (in ms)
          reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts (in ms)
          timeout: 20000,
        });
        socket.connect();

        socket.on('connect', () => {
          set({ isSocketConnected: true }); // Mark as connected
          console.log('Socket connected');
        });

        socket.on('disconnect', () => {
          set({ isSocketConnected: false }); // Mark as disconnected
          console.log('Socket disconnected');
        });

        set({
          socket: socket,
        });
        socket.on(socketCallEnum.EXCEPTION, ({ message }: { message: string }) => {
          console.log('Exception:', message);
          if (message === socketCallEnum.FORBIDDEN_RESOURCE) {
            localStorage.removeItem(LocalStorageEnum.AUTH_STORAGE);
            sessionStorage.clear();
            socket.disconnect();
            set({ isSocketConnected: false });
          }
          // HotToast({ message: message, type: 'error' });
          set({
            cartLoading: false,
          });
          set({
            users: [],
            cart: [],
          });
        });

        socket.on(
          socketCallEnum.REFFRESH_CART,
          ({ dishes, message }: { dishes: Array<CartInterface>; message?: string }) => {
            set({
              cart: dishes,
              cartLoading: false,
            });
            if (message) {
              HotToast({ message: message, type: 'success' });
            }
          },
        );

        socket.on(socketCallEnum.ORDER_STATUS, ({ message, status }: { message: string; status: OrderStatus }) => {
          switch (status) {
            case OrderStatus.ERROR:
              HotToast({ message: message, type: 'error' });
              break;
            case OrderStatus.SUCCESS:
              HotToast({ message: message, type: 'success' });
              break;
            default:
              HotToast({ message: message, type: 'info' });
              break;
          }
          // socket.emit(socketCallEnum.REFFRESH_CART, (response: RefreshCartResponse) => {
          //   console.log(response);
          // });

          set({
            cartLoading: false,
          });
        });

        socket.on(socketCallEnum.GET_USERS, ({ users }: { users: Array<{ name: string; socketId: string }> }) => {
          const capitalisedUsers = users.map((user) => ({
            name: capitalizeFirstLetter(user.name),
            socketId: user.socketId,
          }));
          set({
            users: capitalisedUsers,
          });
        });

        socket.on(
          socketCallEnum.USERS_ON_TABLE,
          ({ users, message }: { users: Array<{ name: string; socketId: string }>; message: string }) => {
            HotToast({ message: message, type: 'info' });
            const capitalisedUsers = users.map((user) => ({
              name: capitalizeFirstLetter(user.name),
              socketId: user.socketId,
            }));
            set({
              users: capitalisedUsers,
            });
          },
        );

        socket.on(
          socketCallEnum.DISHES_ON_TABLE,
          ({ dishes, message }: { dishes: Array<CartInterface>; message: string }) => {
            HotToast({ message: message, type: 'info' });
            set({
              cart: dishes,
            });
          },
        );

        socket.on(socketCallEnum.CART_DISH, (cart: Array<CartInterface>) => {
          set({
            cart: cart,
            cartLoading: false,
          });
        });

        return () => {
          socket.disconnect();
        };
      },

      joinSession: (userName: string) => {
        const socket = get().socket;
        if (socket) {
          socket.emit(socketCallEnum.JOIN_SESSION, { userName });
        } else {
          console.error('Socket not initialized');
          HotToast({ message: 'Please refresh the page to join the party!', type: 'warning' });
        }
      },

      leaveSession: () => {
        const socket = get().socket;
        if (socket) {
          socket.disconnect();
        } else {
          console.error('Socket not initialized');
        }
      },

      updateSession: (dish: CartInterface) => {
        const socket = get().socket;
        if (socket) {
          socket.emit(socketCallEnum.ADD_DISH, { dish });
        } else {
          console.error('Socket not initialized');
        }
      },

      addToCart: (dish: CartInterface, loading: boolean = true) => {
        if (loading) {
          set({ cartLoading: true });
        }
        const socket = get().socket;

        if (!socket) {
          console.error('Socket not initialized'); //error modal
          return;
        }
        socket.emit(socketCallEnum.ADD_DISH, { dish });
        set({ cartLoading: false });
      },

      updateCart: (
        dishes: Array<{
          uuid: string;
          quantity: number;
        }>,
        loading: boolean = true,
      ) => {
        if (loading) {
          set({ cartLoading: true });
        }

        const socket = get().socket;
        if (!socket) {
          console.error('Socket not initialized');
          return;
        }
        socket.emit(socketCallEnum.UPDATE_DISH_V2, { dishes: dishes });
      },
      clearCart: () => {
        const socket = get().socket;
        if (socket) {
          socket.emit(socketCallEnum.CLEAR_CART);
          set({
            cartLoading: true,
          });
        } else {
          console.error('Socket not initialized');
        }
      },

      removeFromCart: (dishId: string) => {
        const cart = get().cart;
        const dishIndex = cart.findIndex((cartItem) => cartItem.dishId === dishId);

        if (dishIndex !== -1) {
          cart[dishIndex].quantity -= 1;

          if (cart[dishIndex].quantity === 0) {
            cart.splice(dishIndex, 1);
          }

          set({
            cart: [...cart],
          });
        }
      },

      placeOrder: (cookingInstructions: string) => {
        set({ cartLoading: true });
        const socket = get().socket;
        if (socket) {
          socket.emit(socketCallEnum.PLACE_ORDER, { cookingInstructions });
        } else {
          console.error('Socket not initialized'); // error modal
        }
      },

      getCart: () => get().cart,
    }),
    {
      name: LocalStorageEnum.CART,
      partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => !['socket'].includes(key))),
      getStorage: () => sessionStorage,
    },
  ),
);

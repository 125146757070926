import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useState } from 'react';

const Otpinput = ({
  digits,
  otp,
  setOtp,
}: {
  digits: number;
  otp: Array<string>;
  setOtp: (o: Array<string>) => void;
}) => {
  const inputRef = React.useRef<Array<HTMLInputElement>>([]);

  return (
    <div className="w-full max-w-[400px] flex justify-center items-center gap-6 mx-auto">
      {Array.from({ length: digits }).map((_, index) => (
        <input
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'e' || e.key === '.' || e.key === '-' || e.key === '+') {
              e.preventDefault();
            }
            if (e.key === 'Backspace' && index > 0) {
              if (otp[index] === '') {
                inputRef.current[index - 1].focus();
              } else {
                const otpCopy = [...otp];
                otpCopy[index] = '';
                setOtp(otpCopy);
              }
            }
          }}
          ref={(input) => inputRef.current.push(input as HTMLInputElement)}
          onFocus={(e) => e.currentTarget.select()}
          onClick={(e) => e.currentTarget.select()}
          value={otp[index]}
          onChange={(event) => {
            const value = event.target.value;

            if (isNaN(Number(value)) || Number(value) > 9 || Number(value) < 0) return;
            const otpCopy = [...otp];
            otpCopy[index] = value;
            setOtp(otpCopy);

            if (index < digits - 1 && value !== '') {
              inputRef.current[index + 1].focus();
            }
          }}
          key={index}
          type="number"
          maxLength={1}
          className={clsx('border rounded-3xl text-center w-10 h-14 text-3xl focus:outline-gold_start', {
            'bg-gradient-to-b from-gold_start to-gold_end text-white border-0': otp[index],
          })}
        />
      ))}
    </div>
  );
};

export default Otpinput;

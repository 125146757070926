import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

function StaticSearch({
  search,
  setSearch,
  placeholder = 'Search',
}: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
}) {
  return (
    <div className=" w-full px-4 py-2 my-2 border border-border_grey rounded-3xl flex justify-start  items-center shadow-md">
      <div className=" border-r mr-1 pr-1">
        <FiSearch className="text-xl font-bold" color="grey" />
      </div>
      <input
        type="text"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder={placeholder}
        className=" w-full text-base  no-focus-border"
      />
      {search !== '' && <IoClose onClick={() => setSearch('')} className="text-xl font-bold" color="grey" />}
    </div>
  );
}

export default StaticSearch;

import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/animations/loading.json'; // Adjust the path as needed

const Button = ({
  title,
  type,
  onClick,
  isLoading = false,
  className = '',
  disabled = false,
}: {
  title: string;
  type: 'primary' | 'secondary';
  onClick: () => void;
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <motion.button
      aria-label={isLoading ? 'Loading' : title}
      onClick={onClick}
      whileTap={{ scale: 0.97 }}
      className={clsx(className, 'max-w-[500px] mx-auto w-full py-2 rounded-xl flex justify-center items-center', {
        'bg-slate-400 text-white pointer-events-none': isLoading || disabled,
        'bg-primary text-white': type === 'primary',
        'bg-white text-black border border-primary': type === 'secondary',
      })}
      disabled={isLoading || disabled}
    >
      {isLoading ? <Lottie animationData={loadingAnimation} loop={true} style={{ width: 24, height: 24 }} /> : title}
    </motion.button>
  );
};

export default Button;

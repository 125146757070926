import React, { ChangeEvent } from 'react';

const Input = ({
  placeholder,
  type = 'text',
  value,
  onChange,
  name,
  inputMode = 'text',
}: {
  placeholder: string;
  type?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  inputMode?: 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;
}) => {
  return (
    <input
      className="focus:outline-none px-4 py-2 border rounded-xl w-full"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      name={name}
      id=""
      inputMode={inputMode}
    />
  );
};

export default Input;

import React from 'react';
import { IoStar } from 'react-icons/io5';
import { IoStarHalf } from 'react-icons/io5';
import { IoStarOutline } from 'react-icons/io5';
import { RatingType } from '../Interface';
import clsx from 'clsx';

function StarRating({
  rating,
  totalStars,
  totalRatings = 0,
  type = RatingType.PRIMARY,
}: {
  rating: number;
  totalStars: number;
  totalRatings?: number;
  type?: RatingType;
}) {
  return (
    <div
      className={clsx('flex justify-between items-center  w-fit', {
        'gap-2': type === RatingType.PRIMARY,
        'gap-1': type === RatingType.SECONDARY,
      })}
    >
      <div className="flex items-center gap-[2px]">
        {[...Array(totalStars)].map((_, index) => {
          const isHalfStar = rating - index >= 0.5;
          const isFullStar = rating - index >= 1;

          return (
            <div key={index}>
              {isFullStar ? (
                <IoStar
                  className={clsx('text-gold_start   ', {
                    'text-base': type === RatingType.PRIMARY,
                    'text-sm ': type === RatingType.SECONDARY,
                  })}
                />
              ) : isHalfStar ? (
                <IoStarHalf
                  className={clsx('text-gold_start', {
                    'text-base': type === RatingType.PRIMARY,
                    'text-sm ': type === RatingType.SECONDARY,
                  })}
                />
              ) : (
                <IoStarOutline
                  className={clsx('text-gold_start ', {
                    'text-base': type === RatingType.PRIMARY,
                    'text-sm ': type === RatingType.SECONDARY,
                  })}
                />
              )}
            </div>
          );
        })}
      </div>
      <p className="font-normal text-sm">{rating}</p>
      {totalRatings > 0 && <p className="font-normal text-sm">({totalRatings})</p>}
    </div>
  );
}

export default StarRating;

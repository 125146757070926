import ReactGA from 'react-ga4';

export const initializeAnalytics = (measurementId: string) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(measurementId);
  } else {
    console.log('Analytics disabled in non-production environments');
  }
};

export const trackPageView = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
  ReactGA.event({ category, action, label, value });
};

export const trackException = (description: string, fatal = false) => {
  ReactGA.event({
    category: 'Exception',
    action: fatal ? 'Fatal' : 'Non-fatal',
    label: description,
  });
};
